import { Routes, UrlMatchResult, UrlSegment } from "@angular/router"

import { AuthPipe, canActivate } from '@angular/fire/auth-guard'
import { map, pipe } from "rxjs"
import { isDisposableEmail } from "@nx-superprep/utils"
import { isDevMode } from "@angular/core"
//import { hasCustomClaim, isNotAnonymous } from '@angular/fire/auth-guard'

//import { PagenotfoundComponent } from "@nx-superprep/shared"
//import { AppComponent } from "./app.component"

// custom AuthPipes
//const passThrought = (() => of(true)) as AuthPipe
//const passThroughtAnonymous = ((user: User) => map(() => !!user && isGeneratedAnonymousEmail(user.email)) as AuthPipe)
//const authorized: AuthPipe = map((user) => !!user && !!user.email && !isDisposableEmail(user.email))
const authorized: AuthPipe = map((user) => !!user && (user.isAnonymous || !!user.email && !isDisposableEmail(user.email)))
// custom redirect
const redirectUnauthorizedTo: (redirect: string | unknown[]) => AuthPipe = (redirect) => pipe(authorized, map((authorized) => authorized || redirect))
const redirectAuthorizedTo: (redirect: string | unknown[]) => AuthPipe = (redirect) => pipe(authorized, map((authorized) => (authorized && redirect) || true))
// firebase auth guard fn
const redirectLoggedInToDashboard = () => redirectAuthorizedTo(['home'])
const redirectUnauthorizedToWebsite = () => redirectUnauthorizedTo(['website'])

//const redirectAnonymousToDemo = () => isNotAnonymous
//const adminOnly = () => hasCustomClaim('admin')
//const belongsToAccount = (next: { params: { id: unknown; }; }) => hasCustomClaim(`account-${next.params.id}`)

// Matcher for dashboard activeTab routes with optional parameters
const dashboardMatcher = (segments: UrlSegment[]): UrlMatchResult | null => {
  if (segments.length === 0) return null

  const posParams: { [key: string]: UrlSegment } = {}
  let consumed: UrlSegment[] = []

  // Handle home prefix
  if (segments.length === 1 && segments[0].path === "home") {
    posParams.activeTab = new UrlSegment("home", {})
    consumed = segments
    return { consumed, posParams }
  }

  // Handle performance prefix with single moduleId parameter
  if (segments.length === 2 && segments[0].path === "performance") {
    posParams.activeTab = new UrlSegment("performance", {})
    posParams.moduleId = segments[1]
    consumed = segments
    return { consumed, posParams }
  }

  // Handle dashboard or root dynamic paths
  const allowedActiveTabs = ['home', 'library', 'performance']
  const isDashboard = segments[0].path === "dashboard"
  const activeTabIndex = isDashboard ? 1 : 0
  const activeTab = segments[activeTabIndex]?.path

  if (activeTab && allowedActiveTabs.includes(activeTab)) {
    const keys = ["subject", "group", "level", "module"]
    posParams.activeTab = new UrlSegment(activeTab, {})
    consumed = segments.slice(0, activeTabIndex + 1)

    for (let i = activeTabIndex + 1; i < segments.length; i += 2) {
      const key = segments[i]?.path
      const value = segments[i + 1]?.path

      if (keys.includes(key) && value) {
        posParams[`${key}Id`] = new UrlSegment(value, {})
        consumed = [...consumed, segments[i], segments[i + 1]]
      } else {
        break
      }
    }

    return { consumed, posParams }
  }

  return null
}

export const appRoutes: Routes = [
  // Default route
  { path: '', redirectTo: 'website', pathMatch: 'full' },
  // Website route
  { path: 'website', loadComponent: () => import('./features/website/website.component').then(m => m.WebsiteComponent), ...canActivate(redirectLoggedInToDashboard) },
  // Static routes
  { path: 'about', loadComponent: () => import('./features/about/about.component').then(m => m.AboutComponent) },
  { path: 'demo/:subject?', loadComponent: () => import('./features/demo/demo.component').then(m => m.DemoComponent)},
  { path: 'onboarding', redirectTo: '/onboarding/app-values', pathMatch: 'full' },
  { path: 'onboarding/:step', loadComponent: () => import('./features/onboarding/onboarding.component').then(m => m.OnboardingComponent)},
  // Onboarding sub routes matcher
  {
    matcher: (url) => {
      const allowedPrefixes = ['app-values', 'get-started', 'classroom'];
      if (url.length && allowedPrefixes.some(prefix => url[0].path.startsWith(prefix))) {
        return { consumed: url }
      }
      return null;
    },
    loadComponent: () => import('./features/onboarding/onboarding.component').then(m => m.OnboardingComponent),
    pathMatch: 'prefix'
  },
  // Legal sub routes matcher
  {
    matcher: (url) => {
      const allowedPrefixes = ['terms', 'privacy', 'unsubscribe'];
      if (url.length && allowedPrefixes.some(prefix => url[0].path.startsWith(prefix))) {
        return { consumed: url }
      }
      return null;
    },
    redirectTo: ({url}) => `/legal/${url.map(segment => segment.path).join('/')}`,
    pathMatch: 'prefix'
  },
  // Proxy component for unguarded modal views
  {
    matcher: (url) => {
      const allowedPrefixes = ['legal', 'terms', 'privacy', 'unsubscribe', 'login', 'register', 'auth_transfer']
      if (url.length && allowedPrefixes.some(prefix => url[0].path.startsWith(prefix))) {
        return { consumed: url }
      }
      return null;
    },
    loadComponent: () => import('./features/redirect/redirect.component').then(m => m.RedirectComponent),
    pathMatch: 'prefix'
  },
  // Proxy component for guarded modal views
  {
    matcher: (url) => {
      const allowedPrefixes = ['quiz', 'study', 'done', 'complete', 'status', 'pause', 'settings']
      if (url.length && allowedPrefixes.some(prefix => url[0].path.startsWith(prefix))) {
        return { consumed: url }
      }
      return null;
    },
    loadComponent: () => import('./features/redirect/redirect.component').then(m => m.RedirectComponent), ...canActivate(redirectUnauthorizedToWebsite),
    pathMatch: 'prefix'
  },
  // Dynamic dashboard routes
  {
    matcher: dashboardMatcher,
    loadComponent: () =>
      import("./features/popstudy/popstudy.component").then(m => m.PopstudyComponent),
    ...canActivate(redirectUnauthorizedToWebsite)
  },
  // Manager routes
  { path: 'brushup', loadComponent: () => import('./features/brushup/brushup.component').then(m => m.BrushupComponent), ...canActivate(redirectUnauthorizedToWebsite) },
  { path: 'resources', loadComponent: () => import('./features/resources/resources.component').then(m => m.ResourcesComponent), ...canActivate(redirectUnauthorizedToWebsite) },
  // tests
  isDevMode()
  ? { path: 'whisper', loadComponent: () => import('./features/whisper/whisper.component').then(m => m.WhisperComponent), ...canActivate(redirectUnauthorizedToWebsite) }
  : {},
  // fallback
  { path: '**', redirectTo: 'website', pathMatch: 'full' }
]
